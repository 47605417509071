<template>
    <a-dropdown :trigger="['click']">
        <a-menu slot="overlay">
            <a-menu-item 
                v-if="isAuthor && meeting.status !== 'ended'"
                class="text-green-400 flex items-center"
                key="4"
                @click="closeConference()">
                <i class="fi fi-rr-badge-check mr-2"></i>
                Завершить конференцию
            </a-menu-item>
            <a-menu-item 
                v-if="meeting.invite_link && meeting.status !== 'ended'"
                key="2" 
                class="flex items-center"
                @click="inviteLink()">
                <i class="fi fi-rr-link-alt mr-2"></i>
                Ссылка для приглашения
            </a-menu-item>
            <a-menu-item 
                key="3" 
                class="flex items-center"
                @click="share()">
                <i class="fi fi-rr-share mr-2"></i>
                Поделиться
            </a-menu-item>
            <a-menu-item 
                v-if="isAuthor"
                key="5" 
                class="flex items-center"
                @click="openEditDrawer()">
                <i class="fi fi-rr-edit mr-2"></i>
                Редактировать
            </a-menu-item>
            <template v-if="isAuthor">
                <a-menu-divider />
                <a-menu-item 
                    class="text-red-500 flex items-center"
                    key="1"
                    @click="deleteConference()">
                    <i class="fi fi-rr-trash mr-2"></i>
                    Удалить
                </a-menu-item>
            </template>
        </a-menu>
        <a-button 
            icon="fi-rr-menu-dots-vertical" 
            flaticon
            ghost
            shape="circle"
            type="ui" /> 
    </a-dropdown>
</template>

<script>
export default {
    props: {
        isAuthor: {
            type: Boolean,
            requered: true
        },
        meeting: {
            type: Object,
            requered: true
        },
        closeConference: {
            type: Function,
            requered: true
        },
        inviteLink: {
            type: Function,
            requered: true
        },
        share: {
            type: Function,
            requered: true
        },
        deleteConference: {
            type: Function,
            requered: true
        },
        openEditDrawer: {
            type: Function,
            requered: true
        }
    },
}
</script>
